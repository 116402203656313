const vehicleEndpoint = {
  GET_COMPANY_VEHICLES: 'vehicles/',
  GET_COMPANY_VEHICLE: 'vehicles/:id',
  ADD_VEHICLE: 'vehicles/',
  DELETE_VEHICLE: 'vehicles/:id',
  DELETE_TEMP_VEHICLE_BID: 'vehicles/offers/bids/:id',
  EDIT_VEHICLE: 'vehicles/edit/:id',
  MY_BIDS: 'vehicles/offers/bids/user',
  GET_BID_INFO: 'vehicles/offers/bids/:id',
  UPDATE_BID_STATUS: 'vehicles/offers/bids/:id/bidder',
  GET_MY_OFFERS: 'vehicles/offers/user',
  GET_ALL_OFFERS: 'vehicles/offers/search',
  GET_OFFER: 'vehicles/offers/:id',
  GET_OFFER_BIDS: 'vehicles/offers/:id/bids',
  GET_CMR_OPTIONS: 'cargoes/cmr-options',
  CLOSE_OFFER: 'vehicles/offers/:id/close',
  CANCEL_OFFER: 'vehicles/offers/:id/cancel',
  SEND_BID_PRICE: 'vehicles/offers/bids/:id',
  UPDATE_BID_STATUS_AS_OFFERER: 'vehicles/offers/bids/:id/offerer',
  GET_VEHICLE_TYPES: 'vehicles/types',
  GET_VEHILCE_DESCRIPTION: 'vehicles/descriptions',
  BID_ON_VEHICLE_OFFER: 'vehicles/offers/:id/bids',
  ADD_VEHICLE_AND_VEHICLE_OFFER: 'vehicle/addVehicleAndVehicleOffer',
  ADD_VEHICLE_OFFER: 'vehicles/offers',
  GET_VEHICLE_EQUIPMENT: 'vehicles/equipments',
  CHECK_VEHICLE_NAME_EXISTS: 'vehicles/check-name-exists',
  EDIT_VEHICLE_OFFER: 'vehicles/offers/:id/edit',
  CHECK_VEHICLE_OFFER_BIDS: 'vehicles/offers/bids/check'
};

const cargoEndpoint = {
  GET_COMPANY_CARGOES: 'cargoes/',
  GET_COMPANY_CARGO: 'cargoes/:id',
  GET_CARGO_CATEGORIES: 'cargoes/cargo-types',
  ADD_CARGO: 'cargoes/',
  EDIT_CARGO: 'cargoes/:id',
  DELETE_CARGO: 'cargoes/:id',
  GET_MY_OFFERS: 'cargoes/offers/user',
  GET_INCOTERMS: 'cargoes/incoterms',
  GET_CMR_OPTIONS: 'cargoes/cmr-options',
  GET_PALLET_SIZES: 'cargoes/pallet-sizes',
  GET_CONTAINER_SIZES: 'cargoes/container-sizes',
  GET_CONTAINER_TYPES: 'cargoes/container-types',
  SEARCH_CARGO_OFFERS: 'cargoes/offers/search',
  ADD_CARGO_OFFER: 'cargoes/offers',
  CANCEL_OFFER: 'cargoes/offers/:id',
  FIND_OFFER: 'cargoes/offers/:id',
  GET_CARGO_BIDS: 'cargoes/offers/:id/bids',
  SEARCH_MY_BIDS: 'cargoes/offers/bids/user',
  GET_CARGO_BID: 'cargoes/offers/bids/:id',
  ACCEPT_OFFER: 'cargoes/offers/bids/:id/accept',
  DECLINE_OFFER: 'cargoes/offers/bids/:id/decline',
  WITHDRAW_BID: 'cargoes/offers/bids/:id/withdraw',
  SEND_BID_PRICE: 'cargoes/offers/bids/:id/price',
  BID_CARGO_OFFER: 'cargoes/offers/:id/bids',
  CHECK_CARGO_NAME_EXISTS: 'cargoes/check-name-exists',
  GET_PACKAGING_METHODS: 'cargoes/packaging-methods',
  EDIT_CARGO_OFFER: 'cargoes/offers/:id/edit',
  CHECK_CARGO_OFFER_BIDS: 'cargoes/offers/bids/check'
};

const warehouseEndpoint = {
  GET_WAREHOUSE_OFFERS: 'warehouses/offers/search',
  ADD_WAREHOUSE_OFFER: 'warehouses/offers',
  BID_WAREHOUSE_OFFER: 'warehouses/offers/:id/bids',
  GET_WAREHOUSE_OFFER: 'warehouses/offers/:id',
  GET_BIDS_FOR_WAREHOUSE_OFFER: 'warehouses/offers/:id/bids',
  WITHDRAW_WAREHOUSE_OFFER: 'warehouses/offers/:id/cancel',
  GET_MY_OFFERS: 'warehouses/offers/user',
  ADD_COMPANY_WAREOUSE: 'warehouses/',
  GET_COMPANY_WAREHOUSES: 'warehouses',
  DELETE_WAREHOUSE: 'warehouses/:id',
  GET_COMPANY_WAREHOUSE: 'warehouses/:id',
  EDIT_WAREHOUSE: 'warehouses/edit/:id',
  GET_BID: 'warehouses/offers/bids/:id',
  DELETE_TEMP_WAREHOUSE_BID: 'warehouses/offers/bids/:id',
  UPDATE_BID_STATUS: 'warehouses/offers/bids/:id/bidder',
  SEND_BID_PRICE: 'warehouses/offers/bids/:id/price',
  UPDATE_BID_STATUS_AS_OFFERER: 'warehouses/offers/bids/:id/offeror',
  MY_BIDS: 'warehouses/offers/bids/user',
  CLOSE_OFFER: 'warehouses/offers/:id/close',
  CHECK_WAREHOUSE_NAME_EXISTS: 'warehouses/check-name-exists',
  GET_WAREHOUSE_EQUIPMENT: 'warehouses/equipments',
  GET_WAREHOUSE_PRICING_INTERVAL: 'warehouses/pricing-interval',
  GET_WAREHOUSE_STORAGE_TYPES: 'warehouses/storage-types',
  GET_WAREHOUSE_STORAGE_UNITS: 'warehouses/storage-units',
  GET_WAREHOUSE_TEMPRATURE_CONTROLS: 'warehouses/temperature-controls',
  GET_WAREHOUSE_TYPES: 'warehouses/types',
  EDIT_WAREHOUSE_OFFER: 'warehouses/offers/:id/edit',
  CHECK_WAREHOUSE_OFFER_BIDS: 'warehouses/offers/bids/check'
};

const companyEndpoint = {
  ADD_COMPANY: 'companies/',
  VIEW_COMPANY: 'companies/:id',
  COMPANY_EXISTS: 'companies/:country/:vatin',
  INVITE_USER: 'companies/invite',
  INVITED_USER_DATA: 'companies/invites/:hash',
  USER_INVITE_ACCEPT: 'companies/:id/users/invites/accept',
  USER_INVITE_DECLINE: 'companies/:id/users/invites/decline',
  DEACTIVATE_USER: 'companies/users/:id/deactivate',
  ACTIVATE_USER: 'companies/users/:id/activate',
  ADD_USER_REQUEST: 'companies/:id/request',
  CANCEL_JOIN_REQUEST: 'companies/:id/users/requests/cancel',
  ACCEPT_USER_REQUEST: 'companies/users/:id/requests/accept',
  DECLINE_USER_REQUEST: 'companies/users/:id/requests/decline',
  CANCEL_INVITE: 'companies/users/:id/invites/cancel',
  REMOVE_USER: 'companies/users/:id/remove',
  EDIT_USER: 'companies/users/:id/roles',
  GET_DASHBOARD_ANALYTICS: 'companies/dashboard/analytics',
  GET_DOCUMENTS: 'companies/documents?type=:type',
  GET_DOCUMENT: 'companies/documents/:id',
  EDIT_DOCUMENT: 'companies/documents/:id',
  DELETE_DOCUMENT: 'companies/documents/:id',
  DOCUMENT_TYPES: 'companies/documents/types',
  ADD_DOCUMENT: 'companies/documents/',
  ADD_TUNNEL_DOCUMENT: 'companies/documents/tunnel',
  SEND_DOCUMENT: 'companies/documents/send',
  REMOVE_TUNNEL_DOCUMENT: 'companies/documents/remove',
  LEAVE_COMPANY: 'companies/leave',
  CHANGE_OWNER: 'companies/owner',
  ADD_COMPANY_LOGO: 'companies/:id/avatars',
  GET_COMPANY_TYPES: 'companies/types',
  GET_COMPANY_RATING_HOSTORY: 'companies/:id/ratings/history',
  GET_COMPANIES_OVERALL_RATINGS: 'companies/:id/ratings',
  GET_COMPANIES_KC_RATINGS: 'companies/:id/ratings/kc-scores',
  GENERATE_CMR_AGREEMENT: 'companies/documents/cmr-agreement'
};

const userEndpoints = {
  LOGIN: 'users/login',
  REGISTER: 'users/register',
  USER_PERMISSIONS: 'users/user/permissions',
  GET_COMPANY_USERS: 'companies/users',
  GET_USER_COMPANY_INFO: 'companies/users/:id',
  GET_UNREGISTERED_USER_COMPANY_INFO: 'companies/users/unregistered/:id',
  GET_USER_COMPANIES: 'companies',
  GET_USER_ROLES: 'user/roles',
  GET_USER_PERSONAL_INFO: 'users/user/personal-info/:id',
  USER_VERIFICATION: 'users/:hash/:platform/:os/:browser',
  GET_USER_FROM_VERIFICATION_HASH: 'users/verification/:hash',
  GET_USER_FROM_PASSWORD_HASH: 'users/password/:hash',
  GET_USER_INFO: 'users/user/info',
  GET_USER_SETTINGS: 'users/user/settings',
  USER_EDIT_PROFILE: 'users/user/edit',
  USER_CHANGE_PASSWORD: 'users/passwords',
  USER_CHECK_CURRENT_PASSWORD: 'users/passwords/check',
  PASSWORD_RESET_EMAIL_RESPONSE: 'users/passwords/reset/email',
  PASSWORD_RESET_INFO: 'users/passwords/reset/:hash/info',
  RESET_PASSWORD: 'users/passwords/reset',
  USER_PROFILE_PICTURE: 'users/user/avatars',
  POST_PROFILE_PICTURE: 'users/avatars',
  DELETE_USER_PROFILE: 'users/user/delete',
  GET_OWNERS_COMPANIES: 'companies/owner',
  CHECK_COMPANY_VERIFICATION_STATUS: 'users/user/companies/verification-status?id=:id', //?{checkSettings}
  RESEND_VERIFICATION_EMAIL: 'users/email/verification',
  SET_USER_TUNNEL_STEP: 'users/tunnel-step',
  SET_USER_OPERATING_COUNTRIES: 'users/operating-countries',
  ACCEPT_INVITATION: 'users/accept-invitation/:id',
  SET_NO_COMPANY: 'users/has-company'
};

const contactUsEndpoint = {
  SEND_CONTACT_US_MESSAGE: 'support/messages'
};

const agreementEndpoint = {
  UNPAID_AGREEMENTS: '/agreement/unpaid',
  SEARCH_AGREEMENTS: 'agreements/',
  FIND_AGREEMENT: 'agreements/vehicle-agreements/:id',
  FIND_AGREEMENT_CARGO: 'agreements/cargo-agreements/:id',
  FIND_AGREEMENT_WAREHOUSE: 'agreements/warehouse-agreements/:id',
  SEARCH_AGREEMENT_RATING: 'agreements/:id/ratings',
  VEHICLE_RATE_OFFER: 'agreements/ratings/vehicle-offers/agreements/:id',
  VEHICLE_RATE_BID: 'agreements/ratings/vehicle-bids/agreements/:id',
  CARGO_RATE_OFFER: 'agreements/ratings/cargo-offers/agreements/:id',
  CARGO_RATE_BID: 'agreements/ratings/cargo-bids/agreements/:id',
  WAREHOUSE_RATE_OFFER: 'agreements/ratings/warehouse-offers/agreements/:id',
  WAREHOUSE_RATE_BID: 'agreements/ratings/warehouse-bids/agreements/:id',
  GET_AGREEMENTS_FROM_IDS: 'agreement/findAgreements' // :agreementIds
};

const transactionEndpoint = {
  GET_IN_PROGRESS: 'transaction/inProgress',
  GET_COMPLETED: 'transaction/completed',
  GET_TRANSACTION: 'transaction/:id'
};

const paymentEndpoint = {
  GET_DASHBOARD_DATA: 'payment',
  GET_CARD_DATA: 'payment/customers/payment-methods',
  GET_UNPAID_FEES: 'payment/fees/unpaid',
  GET_INVOICES: 'payment/invoices',
  GET_INVOICE: 'payment/invoices/:id',
  CREATE_TRANSACTION: 'payment/transactions',
  UPDATE_TRANSACTION: 'payment/transactions',
  DELETE_CREDIT_CARD: 'payment/customers/payment-methods'
};

const filtersEndpoint = {
  SAVE_FILTER: 'filters', //:typeId
  GET_AVALIABLE_FILTER_COUNT: 'filters/available-smart',
  GET_SAVED_FILTERS: 'filters/list',
  GET_SAVED_FILTER: 'filters/filter', //:type/:id
  GET_SAVED_FILTERS_TYPE: 'filters/type-list', //:typeId
  TOGGLE_SMART_FILTER: 'filters/toggle-smart',
  EDIT_FILTER: 'filters', //:type/:id
  DELETE_FILTER: 'filters' //:type/:id
};

const notificationsEndpoint = {
  GET_NOTIFICATIONS: 'notifications/',
  DELETE_NOTIFICATION: 'notification/:id',
  DELETE_ALL_NOTIFICATIONS: 'notifications',
  MARK_NOTIFICATION_AS_READ: 'notifications/:id/seen',
  MARK_ALL_NOTIFICATIONS_AS_READ: 'notifications/seen'
};

const utilsEndpoint = {
  GET_CITIES: 'utils/cities', // ?{region_id}{country_id}
  GET_API_VERSION: 'utils/api-version',
  GET_UPDATE_INFO: 'utils/versions/:version/update-notes',
  CHECK_USER_EMAIL_EXISTS: 'utils/emails/exist/:email',
  GET_COUNTRIES: 'utils/countries',
  GET_BACKEND_DATE_TIME: 'utils/server-time'
};

const chatEndpoint = {
  SEND_MESSAGE: 'chats/message/send',
  USER_TYPING: 'chats/user/typing',
  GET_OFFER_MESSAGES: 'chats/offer/:id/chat/messages',
  GET_USER_CHATS: 'chats/user',
  CHAT_EXISTS: 'chats/offer/:id/chat/exists',
  CHAT_SET_SEEN: 'chats/message/setseen/:id'
};

const errorHandlingEndpoint = {
  SEND_REPORT: 'support/reports',
  SEND_FRONT_ERROR: 'support/reports/front-error-log',
  SEND_FRONT_ERROR_REPORT: 'support/reports/front-error-message'
};

const billingInfoEndpoint = {
  GET_BILLING_INFO: 'billing-info/',
  UPDATE_BILLING_INFO: 'billing-info/'
};

const settingsEndpoint = {
  GET_USER_SETTINGS: 'settings/',
  UPDATE_USER_SETTINGS: 'settings/'
};

const subscriptionsEndpoint = {
  GET_SUBSCRIPTIONS: 'subscriptions/',
  CANCEL_SUBSCRIPTIONS: 'subscriptions/',
  GET_USER_SUBSCRIPTION: 'subscriptions/users'
};

const eventEndpoint = {
  ADD_NEW_EVENT: 'events/submit',
  GET_EVENTS_IN_DATE_RANGE: 'events/date-range',
  GET_EVENT_DETAILS: 'events/event-details',
  QUICK_UPDATE_EVENT: 'events/quick-update-event',
  GET_USERS_WITH_EVENTS: 'events/users-with-events',
  UPDATE_EVENT: 'events/update-event/{eventId}',
  // GET_EVENT_LOG_HISTORY: 'events/event-audit-logs'
  GET_EVENT_LOG_HISTORY: 'events/event-audit-logs/{eventId}',
  GET_EVENT_DOCUMENTS: 'events/event-documents/{eventId}'
};

const addressBookEndpoint = {
  GET_ADDRESS_BOOK: 'address-book/address-book-list',
  GET_USER_SAVED_CONTACTS: 'address-book/user-contacts-for-select',
  GET_USER_SAVED_CONTACTS_PLUS: 'address-book/user-contacts-plus-for-select',
  CREATE_NEW_CONTACT: 'address-book/add-new-contact',
  EDIT_CONTACT: 'address-book/update-contact/{contactId}',
  REMOVE_CONTACT: 'address-book/remove-contact/{contactId}',
  LINK_EXISTING_USER: 'address-book/link-existing-user'
};

export {
  vehicleEndpoint,
  cargoEndpoint,
  warehouseEndpoint,
  companyEndpoint,
  userEndpoints,
  agreementEndpoint,
  transactionEndpoint,
  notificationsEndpoint,
  utilsEndpoint,
  errorHandlingEndpoint,
  settingsEndpoint,
  contactUsEndpoint,
  paymentEndpoint,
  billingInfoEndpoint,
  subscriptionsEndpoint,
  filtersEndpoint,
  chatEndpoint,
  eventEndpoint,
  addressBookEndpoint
};
