import {
  SET_CARGO_OFFERS_FILTER,
  CLEAR_CARGO_OFFERS_FILTER,
  CLEAR_CARGO_OFFERS_FILTER_CARGO,
  CLEAR_CARGO_OFFERS_FILTER_ROUTE,
  CLEAR_CARGO_OFFERS_FILTER_SERVICES,
  CLEAR_CARGO_OFFERS_FILTER_REQUESTER,
  CLEAR_CARGO_OFFERS_FILTER_OFFERS
} from '../../../../constants/actions';

const initialState = {
  filters: []
};

export default function reducer(state = initialState, action = {}) {
  let filters = [];
  let selectedFilter = {};
  let selectedIndex = '';

  switch (action.type) {
    case SET_CARGO_OFFERS_FILTER:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        filters.push(action.filter);
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex] = action.filter;
      }

      return {
        ...state,
        filters: filters
      };

    case CLEAR_CARGO_OFFERS_FILTER:
      filters = [...state.filters];
      selectedFilter = filters.filter(
        (filter) => Number(filter.userId) === action.userId && Number(filter.companyId) === action.companyId
      )[0];

      return {
        ...state,
        filters: filters.filter((filter) => {
          return (
            Number(filter.userId) !== Number(selectedFilter?.userId) ||
            Number(filter.companyId) !== Number(selectedFilter?.companyId)
          );
        })
      };

    case CLEAR_CARGO_OFFERS_FILTER_CARGO:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.main_filter.packaging_methods = null;
        filters[selectedIndex].data.advanced_filter.min_total_weight = '';
        filters[selectedIndex].data.advanced_filter.max_total_weight = '';
        filters[selectedIndex].data.advanced_filter.min_volume_m3 = '';
        filters[selectedIndex].data.advanced_filter.max_volume_m3 = '';
        filters[selectedIndex].data.advanced_filter.min_volume_l = '';
        filters[selectedIndex].data.advanced_filter.max_volume_l = '';
        filters[selectedIndex].data.advanced_filter.adr = '';

        return {
          ...state,
          filters: filters
        };
      }

    case CLEAR_CARGO_OFFERS_FILTER_ROUTE:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.main_filter.country = '';
        filters[selectedIndex].data.main_filter.country_temp = '';
        filters[selectedIndex].data.main_filter.type = '0';
        filters[selectedIndex].data.main_filter.departure_date = {
          date_from: null,
          date_to: null
        };
        (filters[selectedIndex].data.main_filter.departure = [
          {
            departure_field: null,
            departure_city: '',
            departure_country: '',
            departure_region: '',
            departure_street: '',
            postal_code_departure: '',
            departure_radius: false,
            departure_radius_value: 10,
            departure_lat: '',
            departure_lon: ''
          }
        ]),
          (filters[selectedIndex].data.main_filter.arrival = [
            {
              arrival_field: null,
              arrival_city: '',
              arrival_country: '',
              arrival_region: '',
              arrival_street: '',
              postal_code_arrival: '',
              arrival_radius: false,
              arrival_radius_value: 10,
              arrival_lat: '',
              arrival_lon: ''
            }
          ]);
        filters[selectedIndex].data.advanced_filter.collective_transport = '';

        return {
          ...state,
          filters: filters
        };
      }

    case CLEAR_CARGO_OFFERS_FILTER_SERVICES:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.advanced_filter.frigo = '';
        filters[selectedIndex].data.advanced_filter.tail_lift = '';
        filters[selectedIndex].data.advanced_filter.customs_clearing = '';
        filters[selectedIndex].data.advanced_filter.cmr_options = '';
        filters[selectedIndex].data.advanced_filter.incoterms = '';

        return {
          ...state,
          filters: filters
        };
      }

    case CLEAR_CARGO_OFFERS_FILTER_REQUESTER:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.origin_country = '';
        filters[selectedIndex].data.main_filter.rating = null;
        filters[selectedIndex].data.main_filter.origin_country = '';
        filters[selectedIndex].data.main_filter.business_type = '';

        return {
          ...state,
          filters: filters
        };
      }

    case CLEAR_CARGO_OFFERS_FILTER_OFFERS:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.advanced_filter.show_my_offers = '0';
        filters[selectedIndex].data.advanced_filter.show_company_offers = '1';

        return {
          ...state,
          filters: filters
        };
      }

    default:
      return state;
  }
}
