import {
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_ERROR
} from '../../../../constants/actions';

const initialState = {
  isFetching: null,
  error: null,
  timestamp: null,
  settings: null,
  status: 'IDLE'
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        timestamp: new Date().toUTCString(),
        status: 'FETCHING'
      };
    case GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        settings: action.data,
        timestamp: new Date().toUTCString(),
        status: 'SUCCESS'
      };
    case GET_USER_SETTINGS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: new Date().toUTCString(),
        status: 'FAIL'
      };
    default:
      return state;
  }
}
